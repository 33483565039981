<template>
  <div class="blue-skin">
    <div id="main-wrapper">
      <app-header />

      <section class="gray pt-4">
        <div class="container">
          <small class="float-end">Updated at 2022-08-28</small>
          <h2>Terms And Conditions</h2>
          <div class="card">
            <div class="card-body">
              <div>
                <div>
                  <div>
                    <p>
                      These Terms of Use constitute a legally binding agreement
                      made between you, whether personally or on behalf of an
                      entity (“you”) and Realhub Ltd (“we,” “us” or “our”),
                      concerning your access to and use of the www.realhub.co.ke
                      website as well as any other media form, media channel,
                      mobile website or mobile application related, linked, or
                      otherwise connected thereto (collectively, the “Site”).
                    </p>
                    <p>
                      You agree that by accessing the Site, you have read,
                      understood, and agree to be bound by all of these Terms of
                      Use. If you do not agree with all of these Terms of Use,
                      then you are expressly prohibited from using the Site and
                      you must discontinue use immediately.
                    </p>
                    <p>
                      Supplemental terms and conditions or documents that may be
                      posted on the Site from time to time are hereby expressly
                      incorporated herein by reference. We reserve the right, in
                      our sole discretion, to make changes or modifications to
                      these Terms of Use at any time and for any reason.
                    </p>
                    <p>
                      We will alert you about any changes by updating the “Last
                      updated” date of these Terms of Use, and you waive any
                      right to receive specific notice of each such change.
                    </p>
                    <p>
                      It is your responsibility to periodically review these
                      Terms of Use to stay informed of updates. You will be
                      subject to, and will be deemed to have been made aware of
                      and to have accepted, the changes in any revised Terms of
                      Use by your continued use of the Site after the date such
                      revised Terms of Use are posted.
                    </p>
                    <p>
                      The information provided on the Site is not intended for
                      distribution to or use by any person or entity in any
                      jurisdiction or country where such distribution or use
                      would be contrary to law or regulation or which would
                      subject us to any registration requirement within such
                      jurisdiction or country.
                    </p>
                    <p>
                      Accordingly, those persons who choose to access the Site
                      from other locations do so on their own initiative and are
                      solely responsible for compliance with local laws, if and
                      to the extent local laws are applicable.
                    </p>
                    <p>
                      Option 1: The Site is intended for users who are at least
                      18 years old. Persons under the age of 18 are not
                      permitted to use or register for the Site.
                    </p>
                    <p>
                      Option 2: All users who are minors in the jurisdiction in
                      which they reside (generally under the age of 18) must
                      have the permission of, and be directly supervised by,
                      their parent or guardian to use the Site. If you are a
                      minor, you must have your parent or guardian read and
                      agree to these Terms of Use prior to you using the Site.
                    </p>
                    <h4 class="mt-4">INTELLECTUAL PROPERTY RIGHTS</h4>
                    <p>
                      Unless otherwise indicated, the Site is our proprietary
                      property and all source code, databases, functionality,
                      software, website designs, audio, video, text,
                      photographs, and graphics on the Site (collectively, the
                      “Content”) and the trademarks, service marks, and logos
                      contained therein (the “Marks”) are owned or controlled by
                      us or licensed to us, and are protected by copyright and
                      trademark laws and various other intellectual property
                      rights.
                    </p>
                    <p>
                      The Content and the Marks are provided on the Site “AS IS”
                      for your information and personal use only.
                    </p>
                    <p>
                      Except as expressly provided in these Terms of Use, no
                      part of the Site and no Content or Marks may be copied,
                      reproduced, aggregated, republished, uploaded, posted,
                      publicly displayed, encoded, translated, transmitted,
                      distributed, sold, licensed, or otherwise exploited for
                      any commercial purpose whatsoever, without our express
                      prior written permission.
                    </p>
                    <p>
                      Provided that you are eligible to use the Site, you are
                      granted a limited license to access and use the Site and
                      to download or print a copy of any portion of the Content
                      to which you have properly gained access solely for your
                      personal, non-commercial use. We reserve all rights not
                      expressly granted to you in and to the Site, the Content
                      and the Marks.
                    </p>
                    <h4 class="mt-4">USER REPRESENTATIONS</h4>
                    <p>By using the Site , you represent and warrant that:</p>
                    <p>
                      (1) all registration information you submit will be true,
                      accurate, current, and complete;
                    </p>
                    <p>
                      (2) you will maintain the accuracy of such information and
                      promptly update such registration information as
                      necessary;
                    </p>
                    <p>
                      (3) You have the legal capacity and you agree to comply
                      with these Terms of Use;
                    </p>
                    <p>[(4) You are not under the age of 18</p>
                    <p>
                      (5) You are not a minor in the jurisdiction in which you
                      reside[, or if a minor, you have received parental
                      permission to use the Site];
                    </p>
                    <p>
                      (6) You will not access the Site through automated or
                      non-human means, whether through a bot, script or
                      otherwise;
                    </p>
                    <p>
                      (7) You will not use the Site for any illegal or
                      unauthorized purpose;
                    </p>
                    <p>
                      (8) Your use of the Site will not violate any applicable
                      law or regulation.
                    </p>
                    <p>
                      If you provide any information that is untrue, inaccurate,
                      not current, or incomplete, we have the right to suspend
                      or terminate your account and refuse any and all current
                      or future use of the Site (or any portion thereof).
                    </p>
                    <h4 class="mt-4">USER REGISTRATION</h4>
                    <p>
                      You may be required to register with the Site. You agree
                      to keep your password confidential and will be responsible
                      for all use of your account and password. We reserve the
                      right to remove, reclaim, or change a username you select
                      if we determine, in our sole discretion, that such
                      username is inappropriate, obscene, or otherwise
                      objectionable.
                    </p>
                    <h4 class="mt-4">PRODUCTS</h4>
                    <p>
                      We make every effort to display as accurately as possible
                      the colors, features, specifications, and details of the
                      products available on the Site. However, we do not
                      guarantee that the colors, features, specifications, and
                      details of the products will be accurate, complete,
                      reliable, current, or free of other errors, and your
                      electronic display may not accurately reflect the actual
                      colors and details of the products.
                    </p>
                    <p>
                      All products are subject to availability and we cannot
                      guarantee that items will be in stock. We reserve the
                      right to discontinue any products at any time for any
                      reason. Prices for all products are subject to change.
                    </p>
                    <h4 class="mt-4">PURCHASES AND PAYMENT</h4>
                    <p>
                      We accept the following forms of payment: M-pesa payment
                      and Bank payments. You agree to provide current, complete,
                      and accurate purchase and account information for all
                      purchases made via the Site. You further agree to promptly
                      update account and payment information, including email
                      address, payment method, and payment card expiration date,
                      so that we can complete your transactions and contact you
                      as needed.
                    </p>
                    <p>
                      You agree to pay all charges at the prices then in effect
                      for your purchases and any applicable shipping fees, and
                      you authorize us to charge your chosen payment provider
                      for any such amounts upon placing your order.
                    </p>
                    <p>
                      If your order is subject to recurring charges, then you
                      consent to our charging your payment method on a recurring
                      basis without requiring your prior approval for each
                      recurring charge, until such time as you cancel the
                      applicable order. We reserve the right to correct any
                      errors or mistakes in pricing, even if we have already
                      requested or received payment.
                    </p>
                    <h4 class="mt-4">PROHIBITED ACTIVITIES</h4>
                    <p>
                      You may not access or use the Site for any purpose other
                      than that for which we make the Site available.
                    </p>
                    <p>
                      The Site may not be used in connection with any commercial
                      endeavors except those that are specifically endorsed or
                      approved by us.
                    </p>
                    <p>As a user of the Site, you agree not to:</p>
                    <p>
                      1.Systematically retrieve data or other content from the
                      Site to create or compile, directly or indirectly, a
                      collection, compilation, database, or directory without
                      written permission from us.
                    </p>
                    <p>
                      2.Make any unauthorized use of the Site, including
                      collecting usernames and/or email addresses of users by
                      electronic or other means for the purpose of sending
                      unsolicited email, or creating user accounts by automated
                      means or under false pretenses.
                    </p>
                    <p>
                      3.Circumvent, disable, or otherwise interfere with
                      security-related features of the Site, including features
                      that prevent or restrict the use or copying of any Content
                      or enforce limitations on the use of the Site and/or the
                      Content contained therein.
                    </p>
                    <p>
                      4.Engage in unauthorized framing of or linking to the
                      Site.
                    </p>
                    <p>
                      5.Trick, defraud, or mislead us and other users,
                      especially in any attempt to learn sensitive account
                      information such as user passwords;
                    </p>
                    <p>
                      6.Make improper use of our support services or submit
                      false reports of abuse or misconduct.
                    </p>
                    <p>
                      7.Engage in any automated use of the system, such as using
                      scripts to send comments or messages, or using any data
                      mining, robots, or similar data gathering and extraction
                      tools.
                    </p>
                    <p>
                      8.Interfere with, disrupt, or create an undue burden on
                      the Site or the networks or services connected to the
                      Site.
                    </p>
                    <p>
                      9.Attempt to impersonate another user or person or use the
                      username of another user.
                    </p>
                    <p>10.Sell or otherwise transfer your profile.</p>
                    <p>
                      11.Use any information obtained from the Site in order to
                      harass, abuse, or harm another person.
                    </p>
                    <p>
                      12.Use the Site as part of any effort to compete with us
                      or otherwise use the Site and/or the Content for any
                      revenue-generating endeavor or commercial enterprise.
                    </p>
                    <p>
                      13.Decipher, decompile, disassemble, or reverse engineer
                      any of the software comprising or in any way making up a
                      part of the Site.
                    </p>
                    <p>
                      14.Attempt to bypass any measures of the Site designed to
                      prevent or restrict access to the Site, or any portion of
                      the Site.
                    </p>
                    <p>
                      15.Harass, annoy, intimidate, or threaten any of our
                      employees or agents engaged in providing any portion of
                      the Site to you.
                    </p>
                    <p>
                      16.Delete the copyright or other proprietary rights notice
                      from any Content.
                    </p>
                    <p>
                      17.Copy or adapt the Site’s software, including but not
                      limited to Flash, PHP, HTML, JavaScript, or other code.
                    </p>
                    <p>
                      18.upload or transmit (or attempt to upload or to
                      transmit) viruses, Trojan horses, or other material,
                      including excessive use of capital letters and spamming
                      (continuous posting of repetitive text), that interferes
                      with any party’s uninterrupted use and enjoyment of the
                      Site or modifies, impairs, disrupts, alters, or interferes
                      with the use, features, functions, operation, or
                      maintenance of the Site.
                    </p>
                    <p>
                      19.upload or transmit (or attempt to upload or to
                      transmit) any material that acts as a passive or active
                      information collection or transmission mechanism,
                      including without limitation, clear graphics interchange
                      formats (“gifs”), 1×1 pixels, web bugs, cookies, or other
                      similar devices (sometimes referred to as “spyware” or
                      “passive collection mechanisms” or “pcms”).
                    </p>
                    <p>
                      20.Except as may be the result of standard search engine
                      or Internet browser usage, use, launch, develop, or
                      distribute any automated system, including without
                      limitation, any spider, robot, cheat utility, scraper, or
                      offline reader that accesses the Site, or using or
                      launching any unauthorized script or other software.
                    </p>
                    <p>
                      21.Disparage, tarnish, or otherwise harm, in our opinion,
                      us and/or the Site.
                    </p>
                    <p>
                      22.Use the Site in a manner inconsistent with any
                      applicable laws or regulations.
                    </p>
                    <h4 class="mt-4">USER GENERATED CONTRIBUTIONS</h4>
                    <p>
                      The Site may invite you to chat, contribute to, or
                      participate in blogs, message boards, online forums, and
                      other functionality, and may provide you with the
                      opportunity to create, submit, post, display, transmit,
                      perform, publish, distribute, or broadcast content and
                      materials to us or on the Site, including but not limited
                      to text, writings, video, audio, photographs, graphics,
                      comments, suggestions, or personal information or other
                      material (collectively, &quot;Contributions&quot;).
                    </p>
                    <p>
                      Contributions may be viewable by other users of the Site
                      and through third-party websites. As such, any
                      Contributions you transmit may be treated as
                      non-confidential and non-proprietary. When you create or
                      make available any Contributions, you thereby represent
                      and warrant that:
                    </p>
                    <p>
                      1.The creation, distribution, transmission, public
                      display, or performance, and the accessing, downloading,
                      or copying of your Contributions do not and will not
                      infringe the proprietary rights, including but not limited
                      to the copyright, patent, trademark, trade secret, or
                      moral rights of any third party.
                    </p>
                    <p>
                      2.You are the creator and owner of or have the necessary
                      licenses, rights, consents, releases, and permissions to
                      use and to authorize us, the Site, and other users of the
                      Site to use your Contributions in any manner contemplated
                      by the Site and these Terms of Use.
                    </p>
                    <p>
                      3.You have the written consent, release, and/or permission
                      of each and every identifiable inpidual person in your
                      Contributions to use the name or likeness of each and
                      every such identifiable individual person to enable
                      inclusion and use of your Contributions in any manner
                      contemplated by the Site and these Terms of Use.
                    </p>
                    <p>
                      4.Your Contributions are not false, inaccurate, or
                      misleading.
                    </p>
                    <p>
                      5.Your Contributions are not unsolicited or unauthorized
                      advertising, promotional materials, pyramid schemes, chain
                      letters, spam, mass mailings, or other forms of
                      solicitation.
                    </p>
                    <p>
                      6.Your Contributions are not obscene, lewd, lascivious,
                      filthy, violent, harassing, libelous, slanderous, or
                      otherwise objectionable (as determined by us).
                    </p>
                    <p>
                      7.Your Contributions do not ridicule, mock, disparage,
                      intimidate, or abuse anyone.
                    </p>
                    <p>
                      8.Your Contributions do not advocate the violent overthrow
                      of any government or incite, encourage, or threaten
                      physical harm against another.
                    </p>
                    <p>
                      9.Your Contributions do not violate any applicable law,
                      regulation, or rule.
                    </p>
                    <p>
                      10. Your Contributions do not violate the privacy or
                      publicity rights of any third party.
                    </p>
                    <p>
                      11. Your Contributions do not contain any material that
                      solicits personal information from anyone under the age of
                      18 or exploits people under the age of 18 in a sexual or
                      violent manner.
                    </p>
                    <p>
                      12. your Contributions do not violate any federal or state
                      law concerning child pornography, or otherwise intended to
                      protect the health or well-being of minors;
                    </p>
                    <p>
                      13. Your Contributions do not include any offensive
                      comments that are connected to race, national origin,
                      gender, sexual preference, or physical handicap.
                    </p>
                    <p>
                      14. Your Contributions do not otherwise violate, or link
                      to material that violates, any provision of these Terms of
                      Use, or any applicable law or regulation.
                    </p>
                    <p>
                      Any use of the Site in violation of the foregoing violates
                      these Terms of Use and may result in, among other things,
                      termination or suspension of your rights to use the Site.
                    </p>
                    <h4 class="mt-4">GUIDELINES FOR REVIEWS</h4>
                    <p>
                      We may provide you areas on the Site to leave reviews or
                      ratings. When posting a review, you must comply with the
                      following criteria:
                    </p>
                    <p>
                      (1) You should have firsthand experience with the
                      person/entity being reviewed;
                    </p>
                    <p>
                      (2) Your reviews should not contain offensive profanity,
                      or abusive, racist, offensive, or hate language;
                    </p>
                    <p>
                      (3) Your reviews should not contain discriminatory
                      references based on religion, race, gender, national
                      origin, age, marital status, sexual orientation, or
                      disability;
                    </p>
                    <p>
                      (4) Your reviews should not contain references to illegal
                      activity;
                    </p>
                    <p>
                      (5) You should not be affiliated with competitors if
                      posting negative reviews;
                    </p>
                    <p>
                      (6) You should not make any conclusions as to the legality
                      of conduct;
                    </p>
                    <p>
                      (7) You may not post any false or misleading statements;
                    </p>
                    <p>
                      (8) You may not organize a campaign encouraging others to
                      post reviews, whether positive or negative.
                    </p>
                    <p>
                      We may accept, reject, or remove reviews in our sole
                      discretion. We have absolutely no obligation to screen
                      reviews or to delete reviews, even if anyone considers
                      reviews objectionable or inaccurate.
                    </p>
                    <p>
                      Reviews are not endorsed by us, and do not necessarily
                      represent our opinions or the views of any of our
                      affiliates or partners.
                    </p>
                    <h4 class="mt-4">SOCIAL MEDIA</h4>
                    <p>
                      As part of the functionality of the Site, you may link
                      your account with online accounts you have with
                      third-party service providers (each such account, a
                      “Third-Party Account”) by either: (1) providing your
                      Third-Party Account login information through the Site; or
                      (2) allowing us to access your Third-Party Account, as is
                      permitted under the applicable terms and conditions that
                      govern your use of each Third-Party Account.
                    </p>
                    <p>
                      You represent and warrant that you are entitled to
                      disclose your Third-Party Account login information to us
                      and/or grant us access to your Third-Party Account,
                      without breach by you of any of the terms and conditions
                      that govern your use of the applicable Third-Party
                      Account, and without obligating us to pay any fees or
                      making us subject to any usage limitations imposed by the
                      third-party service provider of the Third-Party Account.
                    </p>
                    <p>
                      By granting us access to any Third-Party Accounts, you
                      understand that (1) we may access, make available, and
                      store (if applicable) any content that you have provided
                      to and stored in your Third-Party Account (the “Social
                      Network Content”) so that it is available on and through
                      the Site via your account, including without limitation
                      any friend lists and (2) we may submit to and receive from
                      your Third-Party Account additional information to the
                      extent you are notified when you link your account with
                      the Third-Party Account.
                    </p>
                    <p>
                      Depending on the Third-Party Accounts you choose and
                      subject to the privacy settings that you have set in such
                      Third-Party Accounts, personally identifiable information
                      that you post to your Third-Party Accounts may be
                      available on and through your account on the Site. Please
                      note that if a Third-Party Account or associated service
                      becomes unavailable or our access to such Third-Party
                      Account is terminated by the third-party service provider,
                      then Social Network Content may no longer be available on
                      and through the Site.
                    </p>
                    <p>
                      You will have the ability to disable the connection
                      between your account on the Site and your Third-Party
                      Accounts at any time. PLEASE NOTE THAT YOUR RELATIONSHIP
                      WITH THE THIRD-PARTY SERVICE PROVIDERS ASSOCIATED WITH
                      YOUR THIRD-PARTY ACCOUNTS IS GOVERNED SOLELY BY YOUR
                      AGREEMENT(S) WITH SUCH THIRD-PARTY SERVICE PROVIDERS.
                    </p>
                    <p>
                      We make no effort to review any Social Network Content for
                      any purpose, including but not limited to, for accuracy,
                      legality, or non-infringement, and we are not responsible
                      for any Social Network Content.
                    </p>
                    <p>
                      You acknowledge and agree that we may access your email
                      address book associated with a Third-Party Account and
                      your contacts list stored on your mobile device or tablet
                      computer solely for purposes of identifying and informing
                      you of those contacts who have also registered to use the
                      Site.
                    </p>
                    <p>
                      You can deactivate the connection between the Site and
                      your Third-Party Account by contacting us using the
                      contact information below or through your account settings
                      (if applicable). We will attempt to delete any information
                      stored on our servers that was obtained through such
                      Third-Party Account, except the username and profile
                      picture that become associated with your account.
                    </p>
                    <h4 class="mt-4">SUBMISSIONS</h4>
                    <p>
                      You acknowledge and agree that any questions, comments,
                      suggestions, ideas, feedback, or other information
                      regarding the Site (&quot;Submissions&quot;) provided by
                      you to us are non-confidential and shall become our sole
                      property. We shall own exclusive rights, including all
                      intellectual property rights, and shall be entitled to the
                      unrestricted use and dissemination of these Submissions
                      for any lawful purpose, commercial or otherwise, without
                      acknowledgment or compensation to you.
                    </p>
                    <p>
                      You hereby waive all moral rights to any such Submissions,
                      and you hereby warrant that any such Submissions are
                      original with you or that you have the right to submit
                      such Submissions. You agree there shall be no recourse
                      against us for any alleged or actual infringement or
                      misappropriation of any proprietary right in your
                      Submissions.
                    </p>
                    <h4 class="mt-4">THIRD-PARTY WEBSITES AND CONTENT</h4>
                    <p>
                      The Site may contain (or you may be sent via the Site)
                      links to other websites (&quot;Third-Party Websites&quot;)
                      as well as articles, photographs, text, graphics,
                      pictures, designs, music, sound, video, information,
                      applications, software, and other content or items
                      belonging to or originating from third parties
                      (&quot;Third-Party Content&quot;).
                    </p>
                    <p>
                      Such Third-Party Websites and Third-Party Content are not
                      investigated, monitored, or checked for accuracy,
                      appropriateness, or completeness by us, and we are not
                      responsible for any Third Party Websites accessed through
                      the Site or any Third-Party Content posted on, available
                      through, or installed from the Site, including the
                      content, accuracy, offensiveness, opinions, reliability,
                      privacy practices, or other policies of or contained in
                      the Third-Party Websites or the Third-Party Content.
                    </p>
                    <p>
                      Inclusion of, linking to, or permitting the use or
                      installation of any Third-Party Websites or any
                      Third-Party Content does not imply approval or endorsement
                      thereof by us. If you decide to leave the Site and access
                      the Third-Party Websites or to use or install any
                      Third-Party Content, you do so at your own risk, and you
                      should be aware these Terms of Use no longer govern.
                    </p>
                    <p>
                      You should review the applicable terms and policies,
                      including privacy and data gathering practices, of any
                      website to which you navigate from the Site or relating to
                      any applications you use or install from the Site. Any
                      purchases you make through Third-Party Websites will be
                      through other websites and from other companies, and we
                      take no responsibility whatsoever in relation to such
                      purchases which are exclusively between you and the
                      applicable third party.
                    </p>
                    <p>
                      You agree and acknowledge that we do not endorse the
                      products or services offered on Third-Party Websites and
                      you shall hold us harmless from any harm caused by your
                      purchase of such products or services. Additionally, you
                      shall hold us harmless from any losses sustained by you or
                      harm caused to you relating to or resulting in any way
                      from any Third-Party Content or any contact with
                      Third-Party Websites.
                    </p>
                    <h4 class="mt-4">ADVERTISERS</h4>
                    <p>
                      We allow advertisers to display their advertisements and
                      other information in certain areas of the Site, such as
                      sidebar advertisements or banner advertisements. If you
                      are an advertiser, you shall take full responsibility for
                      any advertisements you place on the Site and any services
                      provided on the Site or products sold through those
                      advertisements.
                    </p>
                    <p>
                      Further, as an advertiser, you warrant and represent that
                      you possess all rights and authority to place
                      advertisements on the Site, including, but not limited to,
                      intellectual property rights, publicity rights, and
                      contractual rights.
                    </p>
                    <h4 class="mt-4">SITE MANAGEMENT</h4>
                    <p>We reserve the right, but not the obligation, to:</p>
                    <p>
                      (1) Monitor the Site for violations of these Terms of Use;
                    </p>
                    <p>
                      (2) Take appropriate legal action against anyone who, in
                      our sole discretion, violates the law or these Terms of
                      Use, including without limitation, reporting such user to
                      law enforcement authorities;
                    </p>
                    <p>
                      (3) In our sole discretion and without limitation, refuse,
                      restrict access to, limit the availability of, or disable
                      (to the extent technologically feasible) any of your
                      Contributions or any portion thereof;
                    </p>
                    <p>
                      (4) In our sole discretion and without limitation, notice,
                      or liability, to remove from the Site or otherwise disable
                      all files and content that are excessive in size or are in
                      any way burdensome to our systems;
                    </p>
                    <p>
                      (5) Otherwise manage the Site in a manner designed to
                      protect our rights and property and to facilitate the
                    </p>
                    <p>proper functioning of the Site.</p>
                    <h4 class="mt-4">PRIVACY POLICY</h4>
                    <p>
                      We care about data privacy and security. By using the
                      Site, you agree to be bound by our Privacy Policy, which
                      is incorporated into these Terms of Use. Please be advised
                      the Site is hosted in Kenya.
                    </p>
                    <h4 class="mt-4">COPYRIGHT INFRINGEMENTS</h4>
                    <p>
                      We respect the intellectual property rights of others. If
                      you believe that any material available on or through the
                      Site infringes upon any copyright you own or control,
                      please immediately notify us using the contact information
                      provided below (a “Notification”). A copy of your
                      Notification will be sent to the person who posted or
                      stored the material addressed in the Notification.
                    </p>
                    <h4 class="mt-4">TERM AND TERMINATION</h4>
                    <p>
                      These Terms of Use shall remain in full force and effect
                      while you use the Site. WITHOUT LIMITING ANY OTHER
                      PROVISION OF THESE TERMS OF USE, WE RESERVE THE RIGHT TO,
                      IN OUR SOLE DISCRETION AND WITHOUT NOTICE OR LIABILITY,
                      DENY ACCESS TO AND USE OF THE SITE (INCLUDING BLOCKING
                      CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY REASON OR FOR
                      NO REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY
                      REPRESENTATION, WARRANTY, OR COVENANT CONTAINED IN THESE
                      TERMS OF USE OR OFANY APPLICABLE LAW OR REGULATION. WE MAY
                      TERMINATE YOUR USE OR PARTICIPATION IN THE SITE OR DELETE
                      [YOUR ACCOUNT AND] ANY CONTENT OR INFORMATION THAT YOU
                      POSTED AT ANY TIME, WITHOUT WARNING, IN OUR SOLE
                      DISCRETION.
                    </p>
                    <p>
                      If we terminate or suspend your account for any reason,
                      you are prohibited from registering and creating a new
                      account under your name, a fake or borrowed name, or the
                      name of any third party, even if you may be acting on
                      behalf of the third party.
                    </p>
                    <p>
                      In addition to terminating or suspending your account, we
                      reserve the right to take appropriate legal action,
                      including without limitation pursuing civil, criminal, and
                      injunctive redress.
                    </p>
                    <h4 class="mt-4">MODIFICATIONS AND INTERRUPTIONS</h4>
                    <p>
                      We reserve the right to change, modify, or remove the
                      contents of the Site at any time or for any reason at our
                      sole discretion without notice. However, we have no
                      obligation to update any information on our Site.
                    </p>
                    <p>
                      We also reserve the right to modify or discontinue all or
                      part of the Site without notice at any time.
                    </p>
                    <p>
                      We will not be liable to you or any third party for any
                      modification, price change, suspension, or discontinuance
                      of the Site.
                    </p>
                    <p>
                      We cannot guarantee the Site will be available at all
                      times. We may experience hardware, software, or other
                      problems or need to perform maintenance related to the
                      Site, resulting in interruptions, delays, or errors. We
                      reserve the right to change, revise, update, suspend,
                      discontinue, or otherwise modify the Site at any time or
                      for any reason without notice to you.
                    </p>
                    <p>
                      You agree that we have no liability whatsoever for any
                      loss, damage, or inconvenience caused by your inability to
                      access or use the Site during any downtime or
                      discontinuance of the Site. Nothing in these Terms of Use
                      will be construed to obligate us to maintain and support
                      the Site or to supply any corrections, updates, or
                      releases in connection therewith.
                    </p>
                    <h4 class="mt-4">GOVERNING LAW</h4>
                    <p>
                      These Terms of Use and your use of the Site are governed
                      by and construed in accordance with the laws of Kenya
                      applicable to agreements made and to be entirely performed
                      without regard to its conflict of law principles.
                    </p>
                    <h4 class="mt-4">DISPUTE RESOLUTION</h4>
                    <p>
                      Option 1: Any legal action of whatever nature brought by
                      either you or us (collectively, the “Parties” and
                      inpidually, a “Party”) shall be commenced or prosecuted in
                      the state and federal courts located in Kenya
                    </p>
                    <p>
                      Option 2: Informal Negotiations To expedite resolution and
                      control the cost of any dispute, controversy, or claim
                      related to these Terms of Use (each a &quot;Dispute&quot;
                      and collectively, the “Disputes”) brought by either you or
                      us (inpidually, a “Party” and collectively, the
                      “Parties”), the Parties agree to first attempt to
                      negotiate any Dispute (except those Disputes expressly
                      provided below) informally for at least 30 days before
                      initiating arbitration. Such informal negotiations
                      commence upon written notice from one Party to the other
                      Party.
                    </p>
                    <h4 class="mt-4">Binding Arbitration</h4>
                    <p>
                      If the Parties are unable to resolve a Dispute through
                      informal negotiations, the Dispute (except those Disputes
                      expressly excluded below) will be finally and exclusively
                      resolved by binding arbitration. YOU UNDERSTAND THAT
                      WITHOUT THIS PROVISION, YOU WOULD HAVE THE RIGHT TO SUE IN
                      COURT AND HAVE A JURY TRIAL.
                    </p>
                    <p>
                      Option 3: Binding Arbitration To expedite resolution and
                      control the cost of any dispute, controversy or claim
                      related to these Terms of Use (each a &quot;Dispute&quot;
                      and collectively, “Disputes”), any Dispute brought by
                      either you or us (inpidually, a “Party” and collectively,
                      the “Parties”) shall be finally and exclusively resolved
                      by binding arbitration.
                    </p>
                    <h4 class="mt-4">CORRECTIONS</h4>
                    <p>
                      There may be information on the Site that contains
                      typographical errors, inaccuracies, or omissions,
                      including descriptions, pricing, availability, and various
                      other information. We reserve the right to correct any
                      errors, inaccuracies, or omissions and to change or update
                      the information on the Site at any time, without prior
                      notice.
                    </p>
                    <h4 class="mt-4">DISCLAIMER</h4>
                    <p>
                      THE SITE IS PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS.
                      YOU AGREE THAT YOUR USE OF THE SITE SERVICES WILL BE AT
                      YOUR SOLE RISK. TO THE FULLEST EXTENT PERMITTEDBY LAW,WE
                      DISCLAIMALL WARRANTIES,EXPRESS OR IMPLIED, IN CONNECTION
                      WITH THE SITE ANDYOUR USETHEREOF, INCLUDING,WITHOUT
                      LIMITATION, THE IMPLIED WARRANTIES OFMERCHANTABILITY,
                      FITNESSFOR A PARTICULARPURPOSE, ANDNON-INFRINGEMENT.
                      WEMAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACY
                      OR COMPLETENESS OF THE SITE’S CONTENT OR THE CONTENT OF
                      ANY WEBSITES LINKED TO THIS SITE AND WE WILL ASSUME NO
                      LIABILITY OR RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES,
                      OR INACCURACIES OF CONTENT AND MATERIALS, (2) PERSONAL
                      INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER,
                      RESULTING FROM YOUR ACCESS TO AND USE OF THE SITE, (3) ANY
                      UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR
                      ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL
                      INFORMATION STORED THEREIN, (4) ANY INTERRUPTION OR
                      CESSATION OF TRANSMISSION TO OR FROM THE SITE, (5) ANY
                      BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE
                      TRANSMITTED TO OR THROUGH THE SITE BY ANY THIRD PARTY,
                      AND/OR (6) ANY ERRORS OR OMISSIONS IN ANYCONTENT
                      ANDMATERIALSOR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED
                      AS A RESULT OF THE USE OF ANY CONTENT POSTED, TRANSMITTED,
                      OR OTHERWISEMADE AVAILABLEVIA THESITE. WEDO NOTWARRANT,
                      ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY
                      PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY
                      THROUGH THE SITE, ANY HYPERLINKED WEBSITE, OR ANYWEBSITE
                      ORMOBILE APPLICATION FEATUREDIN ANYBANNER OR OTHER
                      ADVERTISING, AND WE WILL NOT BE A PARTY TO OR IN ANY WAY
                      BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU
                      AND ANY THIRD-PARTY PROVIDERS OF PRODUCTSOR SERVICES.AS
                      WITHTHE PURCHASEOF APRODUCT OR SERVICE THROUGH ANY MEDIUM
                      OR IN ANY ENVIRONMENT, YOU SHOULD USE YOUR BEST JUDGMENT
                      AND EXERCISE CAUTION WHERE APPROPRIATE.
                    </p>
                    <h4 class="mt-4">LIMITATIONS OF LIABILITY</h4>
                    <p>
                      IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS
                      BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT,
                      INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL,
                      OR PUNITIVE DAMAGES,INCLUDING LOST PROFIT, LOST 
                      REVENUE, LOSS OF DATA, OR OTHER DAMAGES ARISING FROM YOUR
                      USE OF THE SITE, 
                      EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF
                      SUCH DAMAGES. 
                      [NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, OUR 
                      LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER AND REGARDLESS
                      OF THE FORM OF 
                      THE ACTION, WILL AT ALL TIMES BE LIMITED TO [THE LESSER
                      OF]
                    </p>
                    <h4 class="mt-4">INDEMNIFICATION</h4>
                    <p>
                      You agree to defend, indemnify, and hold us harmless,
                      including our subsidiaries, affiliates, and all of our 
                      respective officers, agents, partners, and employees, from
                      and against any loss, damage, liability, claim, or 
                      demand, including reasonable attorneys’ fees and expenses,
                      made by any third party due to or arising out 
                      of: (1) [your Contributions]; (2) use of the Site; (3)
                      breach of these Terms of Use; (4) any breach of your 
                      representations and warranties set forth in these Terms of
                      Use; (5) your violation of the rights of a third 
                      party, including but not limited to intellectual property
                      rights; or (6) any overt harmful act toward any 
                      other user of the Site with whom you connected via the
                      Site.
                    </p>
                    <p>
                      Notwithstanding the foregoing, we reserve the right, at
                      your expense, to assume the exclusive defense and 
                      control of any matter for which you are required to
                      indemnify us, and you agree to cooperate, at your 
                      expense, with our defense of such claims. We will use
                      reasonable efforts to notify you of any such claim, 
                      action, or proceeding which is subject to this
                      indemnification upon becoming aware of it.
                    </p>
                    <h4 class="mt-4">USER DATA</h4>
                    <p>
                      We will maintain certain data that you transmit to the
                      Site for the purpose of managing the performance of 
                      the Site, as well as data relating to your use of the
                      Site. Although we perform regular routine backups of 
                      data, you are solely responsible for all data that you
                      transmit or that relates to any activity you have undertaken using the Site.</p>
                    <p>
                      You agree that we shall have no liability to you for any
                      loss or corruption of any such data, and you 
                      hereby waive any right of action against us arising from
                      any such loss or corruption of such data.
                    </p>
                    <h4 class="mt-4">
                      ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES
                    </h4>
                    <p>
                      Visiting the Site, sending us emails, and completing
                      online forms constitute electronic communications.
                    </p>
                    <p>
                      You consent to receive electronic communications, and you
                      agree that all agreements, notices, 
                      disclosures, and other communications we provide to you
                      electronically, via email and on the Site, satisfy 
                      any legal requirement that such communication be in
                      writing. YOU HEREBY AGREE TO THE USE OF 
                      ELECTRONICSIGNA TURES,CONTRACTS,
                      ORDERS,AND OTHERRECORDS, AND TO 
                      ELECTRONICDELIVERY OF NOTICES,
                      POLICIES, AND RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA THE SITE.</p>
                    <p>
                      You hereby waive any rights or requirements under any
                      statutes, regulations, rules, ordinances, or other 
                      laws in any jurisdiction which require an original
                      signature or delivery or retention of non-electronic 
                      records, or to payments or the granting of credits by any
                      means other than electronic means.
                    </p>
                    <h4 class="mt-4">MISCELLANEOUS</h4>
                    <p>
                      These Terms of Use and any policies or operating rules
                      posted by us on the Site or in respect to the Site 
                      constitute the entire agreement and understanding between
                      you and us. Our failure to exercise or enforce 
                      any right or provision of these Terms of Use shall not
                      operate as a waiver of such right or provision.
                    </p>
                    <p>
                      These Terms of Use operate to the fullest extent
                      permissible by law. We may assign any or all of our 
                      rights and obligations to others at any time. We shall not
                      be responsible or liable for any loss, damage, 
                      delay, or failure to act caused by any cause beyond our
                      reasonable control.
                    </p>
                    <p>
                      If any provision or part of a provision of these Terms of
                      Use is determined to be unlawful, void, or 
                      unenforceable, that provision or part of the provision is
                      deemed severable from these Terms of Use and 
                      does not affect the validity and enforceability of any
                      remaining provisions. There is no joint venture, 
                      partnership, employment or agency relationship created
                      between you and us as a result of these Terms of
                    </p>
                    <h4 class="mt-4">Use or use of the Site.</h4>
                    <p>
                      You agree that these Terms of Use will not be construed
                      against us by virtue of having drafted them. You 
                      hereby waive any and all defenses you may have based on
                      the electronic form of these Terms of Use and 
                      the lack of signing by the parties hereto to execute these
                      Terms of Use.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <app-footer />
    </div>
  </div>
</template>

<script>
import AppHeader from "../components/AppHeader.vue";
import AppFooter from "../components/AppFooter.vue";

export default {
  components: {
    AppHeader,
    AppFooter,
  },
};
</script>
